.allergen-icon {
  width: 40px;
}

.fullMenu-pic {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.selected-allergen {
  border-radius: 50%;
  border: solid #007bff;
}

.selected-flag {
  border: solid #007bff;
}

.not-selected-img {
  opacity: 0.5;
}

.table-hover:hover {
  box-shadow: inset 0.1875rem 0 0 #007bff;
  background-color: #fbfbfb;
}

.dropzone-dashed-blue {
  border: 1px dashed #007bff;
  padding: 7px;
  text-align: center;
  cursor: copy;
}

.dropzone-dashed-red {
  border: 1px dashed rgb(121, 8, 8);
  padding: 7px;
  text-align: center;
  cursor: copy;
}

.dropzone-solid-blue {
  border: 1px solid #007bff;
  padding: 7px;
  text-align: center;
  cursor: copy;
}

.error-required-icon {
  color: rgb(190, 14, 14);
}

.center-text {
  text-align: center;
}

.spinner-center {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.required-input {
  color: #007bff;
  font-weight: bold;
}

/* Tooltip container */
.tooltip-custom {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-custom .tooltiptext-custom {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip-custom .tooltiptext-custom::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-custom:hover .tooltiptext-custom {
  visibility: visible;
  opacity: 1;
}

button.menu-options,
.menu-options {
  color: grey;
  font-size: 1.2rem !important;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0px 3px;
}

button.menu-options:hover {
  cursor: pointer;
}

button.menu-options:active {
  outline: none;
}

button.menu-options:disabled {
  opacity: 0.2;
}

.fullMenuTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: calc(100% - 145px);
}

.spinner-loader {
  position: absolute;
  top: 500%;
  left: 50%;
}

.spinner-loader-dnd {
  position: relative;
  margin: 2rem auto;
}

.spinner-loader-listing {
  position: absolute;
  top: 26%;
  left: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.test-multi {
  margin: 200px;
}

.rw-multiselect-tag {
  background-color: transparent !important;
  border: 1px #007bff solid !important;
  vertical-align: sub !important;
}

.title-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.main-content {
  background-color: #f5f6f8 !important;
}

.main-content-container.container-fluid {
  background-color: #f5f6f8 !important;
}

.modal-menu {
  max-height: 80vh;
  overflow: auto;
}

.stats-small {
  color: white;
}

.stats-small__label {
  color: #fff !important;
  font-size: 1rem !important;
}

.stats-small__value {
  color: #fff !important;
}

.stats-small__subvalue {
  color: #fff !important;
  font-size: 1rem;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  opacity: 0.8;
}

.stats-small__sublabel {
  font-size: 0.65rem;
}

.stats-small {
  background-position-x: 90%;
  background-repeat: no-repeat;
  background-position-y: 70%;
}

.stats_views {
  background-color: #ff4545 !important;
  background-image: url("../../assets/eye.png");
}

.stats_share {
  background-color: #eec13e !important;
  background-image: url("../../assets/share.png");
}

.stats_allergen {
  background-color: #a345ff !important;
  background-image: url("../../assets/filter.png");
}

.stats_action {
  background-color: #2577d5 !important;
  background-image: url("../../assets/calendar.png");
}

.stats_wishlist {
  background-color: #0dd1a9 !important;
  background-image: url("../../assets/heart.png");
}

.stats_follows {
  background-color: #0dafd1 !important;
  background-image: url("../../assets/user.png");
}

.subkpi-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 2px 10px;
  margin-right: 5px;
}

.whatsapp {
  background-image: url("../../assets/whatsapp-brands.png");
}

.facebook {
  background-image: url("../../assets/facebook-f-brands.png");
}

.twitter {
  background-image: url("../../assets/twitter-brands.png");
}

.phone {
  background-image: url("../../assets/phone.png");
}

.book {
  background-image: url("../../assets/book.png");
}

.dish-favorite__item {
  border-bottom: 1px solid #e1e5eb;
}

.dish-favorite__image img {
  width: 100%;
  border-radius: 0.25rem;
  object-fit: cover;
}

.dish-favorite__content .text-secondary {
  font-size: 1.2rem;
}

.card .list-group-item {
  padding: 1.1rem 1.875rem !important;
}

.stats-small__data {
  z-index: 0 !important;
}

.DateInput_input {
  padding: 2px 2px !important;
  font-size: 14px !important;
}
.centered {
  top: 45%;
  position: relative;
}

.error-text {
  color: #ff4545;
  font-size: 15px;
}

.card-kpi {
  max-width: 25% !important;
}

.stats-small .card-body {
  padding-left: 10% !important;
}

@media (min-width: 1800px) {
  .col-xxl-5 {
    max-width: 40% !important;
    flex: 0 0 40% !important;
  }

  .col-xxl-4 {
    max-width: 33% !important;
    flex: 0 0 33% !important;
  }

  .col-xxl-3 {
    max-width: 25% !important;
    flex: 0 0 25% !important;
  }
}

@media (max-width: 1800px) {
  .card-kpi {
    min-width: 25% !important;
  }

  .chart-views-date {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }

  .chart-views-language {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}

/* Breakpoint LG */
@media (max-width: 1200px) {
  .card-kpi {
    min-width: 33% !important;
  }
}

/* Breakpoint MD */
@media (max-width: 998px) {
  .card-kpi {
    min-width: 50% !important;
  }
}

/* Breakpoint SM */
@media (max-width: 767px) {
  .card-kpi {
    min-width: 50% !important;
  }
}

@media (max-width: 575px) {
  .card-kpi {
    min-width: 100% !important;
  }
}
